import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "event-series"
    }}>{`Event Series`}</h1>
    <p>{`Event series names let you link events together with a keyword. Events linked in this way are automatically created a dedicated series page that's accessible to the public. These series pages can be shared on social media to attract increased attention!`}</p>
    <h2 {...{
      "id": "creating-a-series"
    }}>{`Creating a Series`}</h2>
    <ol>
      <li parentName="ol">{`Enter a 'Series Name' across multiple events that have already been published, or when adding a new event`}</li>
    </ol>
    <h2 {...{
      "id": "search-for-a-series"
    }}>{`Search for a Series`}</h2>
    <ol>
      <li parentName="ol">{`On the staff or public view, search for the series name to find all events in the series`}</li>
      <li parentName="ol">{`When viewing an event's booking page as staff, you will also see series information in the right sidebar`}</li>
      <li parentName="ol">{`Search results that include series events can be refined using the filter options on both the staff and public views`}</li>
    </ol>
    <h2 {...{
      "id": "sharing-event-series-to-the-public"
    }}>{`Sharing Event Series to the Public`}</h2>
    <ol>
      <li parentName="ol">{`To create a link to a series that the public can access, start by searching for the series name on the public events page. A banner will be displayed indicating "Your query exactly matched the name of an event series! Show the series." Click through to view the series page which can be shared.`}</li>
      <li parentName="ol">{`Alternatively, when viewing a series event's booking page as staff, you will see series information in the right sidebar. A Copy Link icon is available from here.`}</li>
    </ol>
    <h3 {...{
      "id": "watch-the-video"
    }}>{`Watch The Video`}</h3>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/LHf5UromyTo" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></YouTube>
    <Alert kind="info" mdxType="Alert">You can add series names to any events that have been published to link them together later.</Alert>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      